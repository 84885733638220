import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import queryString from "query-string";
import axios from "axios";
import LogoQoin from "../assets/image/qoin-logo.png";
import Email from "../assets/image/success-before-new.png";
import Success from "../assets/image/success-new.png";
import LoadingImage from "../assets/image/loading.png";
import "./EmailResponse.css";

function LoadingScreen() {
  return (
    <div className="loading-content">
      <img src={LoadingImage} alt="" />
      <h1>Loading..</h1>
    </div>
  );
}

function redirect() {
  window.location.replace(process.env.URL_DEEPLINK);
}

function EmailResponse(props) {
  const [isSuccess, setIsSuccesss] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const query = new URLSearchParams(window.location.search);
  const key = query.get("key");

  const conditionalTemplate = () => {
    let isMobile = false;
    // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    //   isMobile = true
    // }
    if (isSuccess === true) {
      return (
        <div className="content-group">
          <h1>Verifikasi Email Berhasil</h1>
          <p>
            Sekarang kamu dapat mengakses seluruh layanan QOIN yang telah
            disediakan. Selamat menikmati!
          </p>
          <img src={Success} alt="" className="image-content" />
          {isMobile === true && (
            <button
              onClick={() => {
                redirect();
              }}
            >
              Mulai Explore QOIN
            </button>
          )}
        </div>
      );
    } else {
      return (
        <div className="content-group">
          <h1>Email Sudah Diverifikasi</h1>
          <p>
            Email kamu sudah pernah diverifikasi sebelumnya. Silahkan menikmati
            setiap layanan yang telah kami sediakan!{" "}
          </p>
          <img src={Email} alt="" className="image-content-1" />
          {isMobile === true && (
            <button
              onClick={() => {
                redirect();
              }}
            >
              Kembali ke QOIN
            </button>
          )}
        </div>
      );
    }
  };

  const validateData = async () => {
    const body = {
      EmailKey: key,
    };
    axios
      .post(
        `${process.env.REACT_APP_API_URL}access/v2/email/validation-platform`,
        body,
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.Status !== "failed") {
          setIsSuccesss(true);
        } else {
          setIsSuccesss(false);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsSuccesss(false);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (key) {
      validateData();
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <div className="content">
          <div>
            <img src={LogoQoin} alt="" />
          </div>
          {conditionalTemplate()}
        </div>
      )}
    </>
  );
}

export default withTranslation()(EmailResponse);
