import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import React from 'react';
import EmailResponse from './pages/EmailResponse'

function App() {
  return (
    <BrowserRouter>
      <React.Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route
            exact
            path="/activate"
            name="Email Response"
            render={props => <EmailResponse {...props} />}
          />
          <Redirect from="/" to="/activate" />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
}

export default App;